import React, { PureComponent } from 'react'

import { FormInput } from '@pc/components/FormInput'
import { Button } from '@pc/components/Button'
import { helpers } from '@common'
import Icon from '@pc/components/Icon'

import styles from './style.m.scss'
const className = helpers.classNames.react(styles)
const _className = helpers.classNames.react(styles)

class PhoneInput extends PureComponent {
  state = {
    errMsg: '',
  }

  setInternalErrMsg = (errMsg) => {
    this.setState({
      errMsg,
    })
  }

  onInputError = (errMsg) => {
    const { onError } = this.props
    onError && onError(errMsg)

    this.setInternalErrMsg(errMsg)
  }

  onInputSuccess = () => {
    const { onSuccess } = this.props
    onSuccess && onSuccess()
    this.setInternalErrMsg('')
  }

  render() {
    const { countryCode, platform, errorText } = this.props
    const { errMsg } = this.state
    const showErrText = !!errorText || !!errMsg
    let flagName = 'flag-in'
    if (countryCode === 'PH') {
      flagName = 'flag-ph'
    } else if (countryCode === 'VN') {
      flagName = 'flag-vn'
    }

    return (
      <div
        {...className('input-item-wrapper', {
          hasError: showErrText,
        })}
      >
        <div
          {...className('input-normal input-phone', {
            'input-normal-pc': platform === 'pc',
          })}
        >
          <div {...className('input-phone-flag-wrapper')}>
            <Icon
              {...className('input-phone-flag')}
              icontype="svg"
              name={flagName}
            />
            <FormInput
              {...this.props}
              // 补零
              zeroPadding={true}
              hasTips={false}
              onError={this.onInputError}
              onSuccess={this.onInputSuccess}
            />
          </div>
        </div>
        {!showErrText ? null : (
          <span {...className('text-error')}>{errorText || errMsg}</span>
        )}
      </div>
    )
  }
}

class PasswordInput extends PureComponent {
  state = {
    errMsg: '',
  }

  setInternalErrMsg = (errMsg) => {
    this.setState({
      errMsg,
    })
  }

  onInputError = (errMsg) => {
    const { onError } = this.props
    onError && onError(errMsg)

    this.setInternalErrMsg(errMsg)
  }

  onInputSuccess = () => {
    const { onSuccess } = this.props
    onSuccess && onSuccess()
    this.setInternalErrMsg('')
  }

  render() {
    const { errorText, platform, rightTipContent, page } = this.props
    const { errMsg } = this.state
    const showErrText = !!errorText || !!errMsg

    return (
      <div
        {...className('input-item-wrapper password_wrapper', {
          hasError: showErrText,
        })}
      >
        <div
          {...className('input-password input-normal', {
            'input-normal-pc': platform === 'pc',
            input_order_detail_password: page === 'orderDetail',
          })}
        >
          <FormInput
            {...this.props}
            hasTips={false}
            onError={this.onInputError}
            onSuccess={this.onInputSuccess}
          />
        </div>
        <div {...className('input_password_text')}>
          <span {...className('text-error')}>
            {!showErrText ? null : errorText || errMsg}
          </span>
          {/* {!showErrText ? null : (
          )} */}
          {rightTipContent}
        </div>
      </div>
    )
  }
}

class CodeInput extends PureComponent {
  onBtnClick = () => {
    const { onSend, btnDisable } = this.props

    if (btnDisable) {
      return false
    }

    this.props.onSend()
  }

  render() {
    const { btnDisable, btnText, errText, hasTips, platform, page } = this.props
    const showErrText = !hasTips && !!errText

    return (
      <div
        {...className('input-item-wrapper', {
          hasError: showErrText,
        })}
      >
        <div
          {...className('input-normal input-code', {
            'input-normal-pc': platform === 'pc',
            input_order_detail_code: page === 'orderDetail',
          })}
        >
          <FormInput {...this.props} autocomplete="off" hasTips={false} />
          <Button
            text={btnText}
            disabled={btnDisable}
            onClick={() => this.onBtnClick()}
            platform="pc"
            size="small"
            {...className('send_btn', {
              disabled_btn: btnDisable,
            })}
          />
        </div>
        {!showErrText ? null : (
          <span {...className('text-error')}>{errText}</span>
        )}
      </div>
    )
  }
}

class GraphicInput extends PureComponent {
  render() {
    const { errText, hasTips, url, refresh, platform } = this.props
    const showErrText = !hasTips && !!errText

    return (
      <div
        {...className('graphic-captcha-container', {
          'graphic-captcha-container-pc': platform === 'pc',
        })}
      >
        <div
          {...className('graphic-captcha')}
          onClick={() => refresh && refresh()}
        >
          <Icon icontype="image" src={url} className="graphic-captcha-icon" />
          <Icon
            icontype="svg"
            name="refresh"
            className="graphic-captcha-refresh"
          />
        </div>
        <div
          {...className('input-item-wrapper', {
            hasError: showErrText,
          })}
        >
          <div
            {...className('input-normal input-graphic', {
              'input-normal-pc': platform === 'pc',
            })}
          >
            <FormInput {...this.props} />
          </div>
          {!showErrText ? null : (
            <span {...className('text-error')}>{errText}</span>
          )}
        </div>
      </div>
    )
  }
}

class GraphicInputNew extends PureComponent {
  render() {
    const { errText, hasTips, url, refresh, platform } = this.props
    const showErrText = !hasTips && !!errText

    return (
      <div
        {...className('graphic-captcha-new-container', {
          'graphic-captcha-new-container-pc': platform === 'pc',
        })}
      >
        <div
          {...className('input-item-wrapper', {
            hasError: showErrText,
          })}
        >
          <div
            {...className('input-normal input-graphic', {
              'input-normal-pc': platform === 'pc',
            })}
          >
            <FormInput {...this.props} />
          </div>
          <div
            {...className('graphic-captcha')}
            onClick={() => refresh && refresh()}
          >
            <Icon icontype="image" src={url} className="graphic-captcha-icon" />
            <Icon
              icontype="svg"
              name="refresh"
              className="graphic-captcha-refresh"
            />
          </div>
        </div>

        {!showErrText ? null : (
          <span {...className('text-error')}>{errText}</span>
        )}
      </div>
    )
  }
}

class CouponCodeInput extends PureComponent {
  constructor() {
    super()
    this.state = {
      canDelete: false,
    }

    this.onChange = this.onChange.bind(this)
  }

  componentDidMount() {
    const { onRef } = this.props

    onRef && onRef(this)

    this.makeInputFocus()
  }

  onRef = (ref) => {
    this.inputRef = ref
  }

  makeInputFocus = () => {
    this.inputRef.inputRef.current.focus()
  }

  makeInputBlur = () => {
    this.inputRef.inputRef.current.blur()
  }

  onChange = (value) => {
    const { onChange } = this.props

    onChange && onChange(value)

    value
      ? this.setState({ canDelete: true })
      : this.setState({ canDelete: false })
  }

  clearAll = () => {
    const { afterClear } = this.props

    this.inputRef.clearAll()
    this.makeInputFocus()
    afterClear && afterClear()
  }

  render() {
    const { hasTips, errText } = this.props

    return (
      <div
        {...className('input-coupon', {
          hasError: !!errText,
        })}
      >
        <FormInput
          {...this.props}
          type="text"
          onRef={this.onRef}
          onChange={this.onChange}
          autocomplete="off"
          autoFocus
        />
        <span {...className('error-border')} />
        {hasTips ? null : <span {...className('text-error')}>{errText}</span>}
        <div {...className('icon-delete-wrapper')} onClick={this.clearAll}>
          {this.state.canDelete ? (
            <Icon {...className('icon-delete')} icontype="svg" name="delete" />
          ) : null}
        </div>
      </div>
    )
  }
}

class NormalInput extends PureComponent {
  render() {
    const { errText, hasTips, platform } = this.props

    return (
      <div {...className('normal-input-container')}>
        <div
          {...className('input-normal', {
            'input-normal-pc': platform === 'pc',
          })}
        >
          <FormInput {...this.props} />
        </div>
        {hasTips ? null : <span {...className('text-error')}>{errText}</span>}
      </div>
    )
  }
}

class AdvanceInput extends PureComponent {
  render() {
    const { children, className, disabled, platform, errText } = this.props
    const showErrText = !!errText

    return (
      <div
        {..._className('input-item-wrapper', {
          hasError: showErrText,
        })}
      >
        <div
          {..._className(`input-advanced input-normal ${className}`, {
            'input-disabled': disabled,
            'input-normal-pc': platform === 'pc',
          })}
        >
          <span {..._className('prefix-component')}>{children}</span>
          <FormInput {...this.props} hasTips={false} />
        </div>
        {!showErrText ? null : (
          <span {..._className('text-error')}>{errText}</span>
        )}
      </div>
    )
  }
}

export {
  PhoneInput,
  CodeInput,
  PasswordInput,
  GraphicInput,
  CouponCodeInput,
  NormalInput,
  AdvanceInput,
  GraphicInputNew,
}
